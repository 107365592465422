import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import Etisalat from '../../images/etisalat-auditorium.webp';
import Dancefm from '../../images/dancefm.webp';
import Whotel from '../../images/whotel.webp';
import Hilton from '../../images/hiltonhotel.webp';
import Utc from '../../images/utc.webp';
import './projects.css';
function projects() {
  return (
    
    <>
    <div className="boxed_wrapper" id="project">
    <Helmet>
        <title>Our Recently Completed Acoustic Projects Vol.6</title>
        <meta name="description" content="We are extremely fortunate to be part of these iconic projects (vol.6)."/>
        <meta property="og:title" content="Our Recently Completed Acoustic Projects" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/projects-v6" />
        <link rel="alternate" href="https://www.moovgroup.com/projects-v6" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
    <section className="breadcrumb-section projects-bg">
        <div className="container">
            <span className="page-tag">Recently</span>
            <h1 className="page-title">Completed Projects</h1>
        </div>
    </section>
    
    <section className="portfolio-section section-gap" id="portfolio">
        <div className="container">
            <div className="portfolio-items">
                
                <div className="portfolio-item portfolio-style-four">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6 order-md-2">
                            <div className="portfolio-thumb">
                                <img src={Etisalat} alt="Etisalat Auditorium" />
                            </div>
                            
                            <div className="gallery images">
							    <div className="etisalatauditorium-img"></div>
								<div className="img-bot">
									<div className="etisalatauditorium-img2"></div>
									<div className="etisalatauditorium-img3"></div>
								</div>								 
							</div>
							
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="portfolio-desc">
                                <span className="count">31.</span>
                                <span className="line"></span>
                                <h5 className="title"><Link to="/etisalat-auditorium-acoustics">Etisalat Auditorium</Link></h5>
								<p>Location: Dubai, UAE</p>
								<p>Client: Etisalat</p>
	                            <span className="completed-date">About</span>
                                <p>Emirates Telecommunications Corporation, branded trade name Etisalat, is a multinational Emirati based telecommunications services provider, currently operating in 16 countries across Asia, the Middle East and Africa.</p>
								<span className="completed-date">Scope</span>
                                <p>Supply and Installation of seats. 206 Standard seats + 10 VVIP seats</p>
                                <Link to="/etisalat-auditorium-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
				
                <span className="line-footer1"></span>
               
                <div className="portfolio-item portfolio-style-four">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <div className="portfolio-thumb">
                                <img src={Dancefm} alt="Dance FM" />
                            </div>
                            
                            <div className="gallery images">
							    <div className="dancefm-img"></div>
								<div className="img-bot">
								    <div className="dancefm-img2"></div>
								    <div className="dancefm-img3"></div>
								</div>								 
							</div>
							
                        </div>
                       <div className="col-lg-6 col-md-6">
                            <div className="portfolio-desc">
                                <span className="count">32.</span>
                                <span className="line"></span>
                                <h5 className="title"><Link to="/dance-fm-acoustics">Dance FM</Link></h5>
								<p>Location: Media City, Dubai, UAE</p>
								<p>Client:  SHOC Middle East LLC</p>
								<p>Main Contractor  Almas Design & Décor LLC</p>
                                <span className="completed-date">About</span>
                                <p>Dance FM 97.8 the UAE's only dance station brings you exclusively the best in electronic music from over 100 local and international artists.</p>
								<span className="completed-date">Scope</span>
                                <p>Complete acoustic treatment supply installation, testing and commissioning for on air studio.</p>
                                <Link to="/dance-fm-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
				
                <span className="line-footer1"></span>
                
                <div className="portfolio-item portfolio-style-four">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6 order-md-2">
                            <div className="portfolio-thumb">
                                <img src={Whotel} alt="Whotel" />
                            </div>
                           
                            <div className="gallery images">
							    <div className="whotel-img"></div>
								<div className="img-bot">
									<div className="whotel-img2"></div>
									<div className="whotel-img3"></div>
								</div>								 
						    </div>
							
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="portfolio-desc">
                                <span className="count">33.</span>
                                <span className="line"></span>
                                <h5 className="title"><Link to="/w-hotel-acoustics">W Hotel & Residences</Link></h5>
								<p>Location:Dubai, UAE</p>
								<p>Client: Al Sharq Investment</p>
								<p>Main Contractor  Al-Futtaim Carillion</p>
	                            <span className="completed-date">About</span>
                                <p>This luxury resort, situated on the Crescent of the Palm Jumeirah, is a world className hotel resort and residential complex with a spa, pool areas, water features and other amenities comprising of approximately 100,000 m² of accommodation including 300 hotel rooms and 45 super luxury duplex, triplex apartments and penthouses</p>
								<span className="completed-date">Scope</span>
                                <p>Acoustic treatment (Sound Absorption Panel) to walls and ceilings and associated works to Services rooms such as Generator room, Fire and irrigation pump room, AHU room, Pump room, Dosing plant room, ETS plant room and FCU room of hotel and residence basement.</p>
                                <Link to="/w-hotel-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
                
                <span className="line-footer1"></span>
                
				<div className="portfolio-item portfolio-style-four">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <div className="portfolio-thumb">
                                <img src={Hilton} alt="Hilton Hotel" />
                            </div>
                            
                            <div className="gallery images">
							    <div className="hiltonhotel-img"></div>
								<div className="img-bot">
									<div className="hiltonhotel-img2"></div>
									<div className="hiltonhotel-img3"></div>
								</div>								 
							</div>
							
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="portfolio-desc">
                                <span className="count">34.</span>
                                <span className="line"></span>
                                <h5 className="title"><Link to="/hilton-hotel-acoustics">Double Tree By Hilton Hotel</Link></h5>
								<p>Location: Dubai, UAE</p>
								<p>Client: Dubai Properties</p>
								<p>Consultant  Khatib & Alami</p>
								<p>Main Contractor  Khansaheb Interiors</p>
                                <span className="completed-date">About</span>
                                <p>The project consists of 2B+G+12 Floors, 238 key Hotel Tower which is already constructed as Shell & Core finish including external cladding. The hotel tower covers a total BUA of approx. 37,662m2 and consists of two basement level car parking, main kitchen, laundry, restaurants, retail shops, Reception, Lobby, Banquet Hall, Business Centre, Swimming Pool, Health Club, Guest Rooms & Suites including all MEP.</p>
								<span className="completed-date">Scope</span>
                                <p>Installation of sound absorption panels in Upper Basement, 1st Basement, Ground floor, First floor and top floor of doubletree Hilton hotel.</p>
                                <Link to="/hilton-hotel-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
				
                <span className="line-footer1"></span>
                
                <div className="portfolio-item portfolio-style-four">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6 order-md-2">
                            <div className="portfolio-thumb">
                                <img src={Utc} alt="utc" />
                            </div>
                            
                            <div className="gallery images">
							    <div className="utc-img"></div>
								<div className="img-bot">
									<div className="utc-img2"></div>
									<div className="utc-img3"></div>
								</div>								 
							</div>
							
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="portfolio-desc">
                                <span className="count">35.</span>
                                <span className="line"></span>
                                <h5 className="title"><Link to="/utc-aerospace-systems-acoustics">UTC Aerospace Systems</Link></h5>
								<p>Location:Dubai, UAE</p>
								<p>Client:UTC Aerospace systems</p>
								<p>Contractor  MOOV Construction Solutions LLC</p>
	                                <span className="completed-date">About</span>
                                <p>UTC Aerospace Systems, is one of the world’s largest suppliers of technologically advanced aerospace and defense products. They design, manufacture and service systems and components and provide integrated solutions for commercial, regional, business and military aircraft, helicopters and other platforms. They are also a major supplier to international space programs.</p>
								<span className="completed-date">Scope</span>
                                <p>
                                    <ul>
                                        <li>Our scope for this project is soundproof chamber for UTC.</li>
                                        <li>The soundproofing on walls includes the installation of M.S. framework, CFAB, mass loaded vinyl barrier and cement board.</li>
                                        <li>The soundproofing process on ceiling includes M.S. framework, Installation of vinyl barrier, gypsum board P.E.P.P panel and G.I sheets.</li>
                                    </ul>
							    </p>
                                <Link to="/utc-aerospace-systems-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
                
	    <div className="portfolio-items">
		    <div className="portfolio-item portfolio-style-four">
		        <span className="line-footer"></span>
		    	<div className="row align-items-center">
			        <div className="col-lg-6 col-md-6">
				        <div className="portfolio-container1">
				            <Link to="/projects-v5" className="portfolio-previous">&laquo; Previous</Link>
				        </div>
				    </div>
				    <div className="col-lg-6 col-md-6">
				        
                    </div>
                </div>
            </div>
        </div>
        
        </div>
        </div>
    </section>
    </div>
    </div>
    <Footer />
    </>
      );
    }
    
    export default projects;